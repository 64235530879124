import { Options } from "vue-class-component";
import BaseFunction from "@/mixins/BaseFunction";
import PatrolTableItem from "@/entity/aftersale/inspection/PatrolTableItem";
import EntryDataAPI from "@/api/aftersale/inspection/entry";
import PatrolSaveAPI from "@/api/aftersale/inspection/patrol";

@Options({
    name: "app-patrol-check",
    props: {
        valueList: Object,
    },
})
export default class PatrolCheck extends BaseFunction {
    private activeTab = "COMMON1";
    private loading = false;
    private valueList: PatrolTableItem = new PatrolTableItem();
    private patrolData: PatrolTableItem = new PatrolTableItem();
    private entryList: any = [];

    public mounted() {
        EntryDataAPI.entryData({}).then((res: any) => {
            this.entryList = res.data;
        });
    }

    private entryStatus: any = {};

    public async modelOpened() {
        this.patrolData = Object.assign({}, this.valueList);
        this.entryStatus = this.patrolData.entryList ? JSON.parse(this.patrolData.entryList) : {};
        const entryStatusKeys = Object.keys(this.entryStatus);

        for (let i = 0; i < this.entryList.length; i++) {
            const item = this.entryList[i];
            if (this.$tools.isEmpty(entryStatusKeys)) {
                this.entryStatus[item.entryId.toString()] = 1;
            } else {
                //console.log(entryStatusKeys.includes(item.entryId.toString()));
                if (!entryStatusKeys.includes(item.entryId.toString())) {
                    this.entryStatus[item.entryId.toString()] = 1;
                }
            }
        }
    }

    private setActiveTab(activeTab: string) {
        this.activeTab = activeTab;
    }

    public openModal() {
        this.$nextTick(() => {
            (this.$refs.checkModal as any).openModal();
        }).then();
    }

    public async submitFunction(): Promise<void> {
        if (this.$tools.isEmpty(this.patrolData.patrolReason)) {
            this.toast.error("巡检结果不能为空！");
            return Promise.reject(false);
        }
        if (this.$tools.isEmpty(this.patrolData.patrolOpinion)) {
            this.toast.error("维修意见不能为空！");
            return Promise.reject(false);
        }

        const statusAlertConfig = {
            title: "状态",
            message: "确认提交内容吗？",
            closeButtonName: "取消",
            submitButtonName: "提交",
        };

        await this.$vueAlert(statusAlertConfig)
            .then(async () => {
                //console.log(this.entryStatus);
                this.patrolData.entryList = JSON.stringify(this.entryStatus);
                this.loading = true;
                await PatrolSaveAPI.patrolSave({
                    patrolId: this.patrolData.patrolId,
                    patrolReason: this.patrolData.patrolReason,
                    patrolOpinion: this.patrolData.patrolOpinion,
                    entryList: this.patrolData.entryList,
                })
                    .then((res: any) => {
                        //console.log(res);

                        this.valueList.entryList = this.patrolData.entryList;
                        this.valueList.patrolReason = this.patrolData.patrolReason;
                        this.valueList.patrolOpinion = this.patrolData.patrolOpinion;
                    })
                    .catch(() => {
                        return Promise.reject(false);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
                this.toast.success("提交成功！");
                this.valueList.patrolStatus = 28;
                this.valueList.patrolStatusName = "巡检完成";
            })
            .catch(() => {
                // this.isLoading = false;
                return Promise.reject(false);
            });
    }
}
