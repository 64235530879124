import baseApi from "@/api/baseApi";
import requests from "@/utils/axios";

export default {
    ...baseApi,
    // 模块URL
    modulesUrl: "/aftersale/inspection/entry",
    // 获取启用列表
    entryData(query: any) {
        return requests.post(this.modulesUrl + "/entryData", query);
    },
};
